import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ComparisonResult from "../components/ComparisonResult";
import { ToastContainer } from "react-toastify";
import { IoIosArrowUp } from "react-icons/io";

import {
  filterInsuranceByRegion,
  insuranceCalculator,
  postalResolver,
} from "../utils/helpers";
import { getAllInsurance } from "../Apis";

function PreinformationPage() {
  // Variables declarations
  const [insuranceRemData, setInsuranceRemData] = useState([]);
  const [regionalInsurance, setRegionalInsurance] = useState([]);
  const [insuranceObjData, setInsuranceObjData] = useState(null);
  const [insuranceIndex, setInsuranceIndex] = useState(0);
  const [employedText, setEmployedText] = useState(1);
  const [zipCodeText, setZipCodeText] = useState("");
  const [salaryText, setSalaryText] = useState(0);
  const [currentInsuranceName, setCurrentInsuranceName] = useState("");
  const [cityName, setCityText] = useState("");
  const [calculatedMin, setCalculatedMin] = useState(0);
  const [calculatedDiff, setCalculatedDiff] = useState(0);

  // Main loader to get data
  const mainLoader = async () => {
    try {
      const remData = await getAllInsurance("all");
      console.dir(remData?.insuranceCompanies);
      setInsuranceRemData(remData?.insuranceCompanies);
      setInsuranceObjData(remData?.insuranceCompanies[0]);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    mainLoader().then(null);
  }, []);

  useEffect(() => {
    if (cityName.length > 0 && insuranceObjData) {
      __performCalculations();
    }
  }, [cityName, insuranceObjData, salaryText, zipCodeText, employedText]);

  useEffect(() => {
    const __cityName = postalResolver(zipCodeText);
    setCityText(zipCodeText.length > 2 ? __cityName : "");
    if (zipCodeText.length > 3) __getOperatingRegions();
  }, [zipCodeText]);

  const __getOperatingRegions = () => {
    let __SortedInsurance = filterInsuranceByRegion(insuranceRemData, cityName);
    setRegionalInsurance(__SortedInsurance);
  };

  const __performCalculations = () => {
    const data = {
      salary: salaryText,
      employment: employedText,
      data: regionalInsurance,
    };
    const retResult = insuranceCalculator(data).sort();
    __calculateForDifference(retResult);
  };

  const __calculateForDifference = (data) => {
    const userInsurance = {
      salary: salaryText,
      employment: employedText,
      data: [insuranceObjData],
    };
    const theCheapest = data[0];
    const retResultSingle = insuranceCalculator(userInsurance, false);
    const singleDiff = Number(retResultSingle[0]) - Number(theCheapest);
    setCalculatedMin(Number(theCheapest / 12));
    setCalculatedDiff(Number(singleDiff).toFixed(2));
  };

  const __calculateOnInput = (e) => {
    const v = Number(e.target.value);
    if (cityName.length > 0 && v > 0 && insuranceObjData) {
      __performCalculations();
    } else {
      setCalculatedMin(0);
      setCalculatedDiff(0);
    }
  };

  const btnCompareNext = () => {
    if (!insuranceObjData || !zipCodeText || !salaryText) {
      toast.error("Bitte alle Felder ausfüllen!");
      return;
    }

    // Check if the zip code has exactly 5 numbers
    if (zipCodeText.length !== 5) {
      toast.error("Die Postleitzahl muss genau 5 Ziffern haben!");
      return;
    }

    __getOperatingRegions();
    const nextUrlParams = btoa(
      JSON.stringify({
        salary: salaryText,
        cityName: cityName,
        zipCode: zipCodeText,
        employmentStatus: employedText,
        insuranceIndex: insuranceIndex,
      })
    );

    // Open the URL in a new tab
    window.open(`/comparison-platform?data=${nextUrlParams}`, "_blank");
  };

  return (
    <>
      <div className={"container"}>
        <div className="center">
          <div className="p-4 bg-[#FEF7FA] w-full">
            <div className="grid mb-4">
              <h1 className="text-[24px] sm:text-[30px] md:text-[35px] text-[#8D1558] mb-4 font-miloMedium">
                Beiträge vergleichen und Kasse wechseln
              </h1>
              <div className="grid gap-4">
                {/* First field: Full width */}
                <div className="grid col-span-1">
                  <label
                    htmlFor=""
                    className="text-[16px] text-[#8D1558] font-[400] mb-1 font-miloMedium"
                  >
                    Aktuelle Krankenkasse
                  </label>
                  <select
                    name="x1"
                    id="x1"
                    onChange={(s) => {
                      setInsuranceObjData(insuranceRemData[s.target.value]);
                      setInsuranceIndex(Number(s.target.value));
                      setCurrentInsuranceName(
                        insuranceRemData[Number(s.target.value)]
                      );
                    }}
                    value={insuranceIndex}
                    className="w-full"
                    style={{ fontSize: "16px" }}
                  >
                    <option value={""} disabled={true}>
                      --Krankenkasse auswählen---
                    </option>
                    {insuranceRemData
                      ?.sort((a, b) => {
                        const nameA = a?.companyName?.toLowerCase() || ""; // Handle undefined or null values
                        const nameB = b?.companyName?.toLowerCase() || "";
                        return nameA.localeCompare(nameB);
                      })
                      ?.map(
                        (i, k) =>
                          (i?.sections?.length > 0 ||
                            !i?.sections ||
                            i?.sections.length === 0) && (
                            <option key={k} value={k}>
                              {i?.companyName}
                            </option>
                          )
                      )}
                  </select>
                </div>

                {/* Second row: Three evenly spaced fields */}
                <div className="grid md:grid-cols-3 gap-4">
                  {/* Berufsgruppe */}
                  <div className="grid">
                    <label
                      htmlFor=""
                      className="text-[16px] text-[#8D1558] font-[400] mb-1 font-miloMedium"
                    >
                      Berufsgruppe
                    </label>
                    <select
                      name="x2"
                      onChange={(e) => setEmployedText(Number(e.target.value))}
                      className="w-full p-3"
                      style={{ fontSize: "16px" }}
                    >
                      <option disabled={true} value={""}>
                        --Berufsgruppe auswählen--
                      </option>
                      <option value={1}>Angestellt</option>
                      <option value={2}>Selbstständig</option>
                    </select>
                  </div>

                  {/* Postleitzahl */}
                  <div className="grid">
                    <label
                      htmlFor=""
                      className="text-[16px] text-[#8D1558] font-[400] mb-1 font-miloMedium"
                    >
                      Ihre Postleitzahl
                    </label>
                    <input
                      type="number"
                      onChange={(e) =>
                        setZipCodeText(e.target.value.toString())
                      }
                      className="w-full"
                      style={{ fontSize: "16px" }}
                    />
                  </div>

                  {/* Jahresgehalt */}
                  <div className="grid">
                    <label
                      htmlFor=""
                      className="text-[16px] text-[#8D1558] font-[400] mb-1 font-miloMedium"
                    >
                      Brutto Jahresgehalt Euro
                    </label>
                    <input
                      onBlur={(e) => __calculateOnInput(e)}
                      onMouseLeave={(e) => __calculateOnInput(e)}
                      type="number"
                      onChange={(e) => setSalaryText(Number(e.target.value))}
                      className="w-full"
                      style={{ fontSize: "16px" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {salaryText !== 0 && zipCodeText !== "" && (
              <>
                <hr className="border-t-[#f6bfd8] border-wine-400" />
                <ComparisonResult
                  monthlyContribution={Number(calculatedMin).toFixed(2)}
                  yearlyContribution={Number(calculatedDiff).toFixed(2)}
                />
              </>
            )}
            <hr className="border-t-[#f6bfd8] border-wine-400 mb-4" />

            <button
              onClick={btnCompareNext}
              className="bg-[#8D1558] p-2 w-full text-white rounded font-[MiloMedium]"
            >
              Krankenkasse wechseln
            </button>
          </div>
        </div>
      </div>
      <section>
        <div className="container mx-auto flex justify-between items-center px-4">
          <nav>
            <ul className="flex space-x-6">
              <li>
                <a
                  href="https://www.dentnet.de/impressum"
                  className="hover:underline"
                >
                  Impressum
                </a>
              </li>
              <li>
                <a
                  href="https://www.dentnet.de/datenschutz"
                  className="hover:underline"
                >
                  Datenschutz
                </a>
              </li>
              <li>
                <a
                  href="https://www.dentnet.de/sitemap"
                  className="hover:underline"
                >
                  Sitemap
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </section>
    </>
  );
}

export default PreinformationPage;
