import React from "react";
import CurrentInsuranceCard from "./CurrentInsuranceCard";
import InsuranceCard from "./InsuranceCard";

const ResultsSection = ({ currentInsurance, insurances, isCurrentInsuranceVisible }) => {
  return (
    <div className="w-full lg:w-2/3">
      {isCurrentInsuranceVisible && currentInsurance && (
        <CurrentInsuranceCard insurance={currentInsurance} />
      )}
      {insurances.map((insurance, index) => insurance.isdentnet? (
        <InsuranceCard
          currentInsuranceName = {currentInsurance.companyName}
          key={insurance._id}
          index={insurance.index}
          insurance={insurance}
          isCurrentInsurance={false}
        />
      ):null)}
      {insurances.length === 0 && (
        <div className="text-center py-8">
          <p className="text-gray-600">Keine Ergebnisse gefunden. Bitte passen Sie Ihre Filter an.</p>
        </div>
      )}
    </div>
  );
};

export default ResultsSection;
