import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const ApplicationPageBasicUI = ({
  data,
  onSave,
  title,
  isOpen,
  itemPos,
  extraData,
  isVisible,
  userData,
  selectedInsurance,
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedBefore, setIsExpandedBefore] = useState(false);
  const [tmpDataPrev, setTmpDataPrev] = useState(data);
  const [tmpData, setTmpData] = useState({});
  const [employmentVisibility, setEmploymentVisibility] = useState(0);
  const [selfEmploymentVisibility, setSelfEmploymentVisibility] = useState(0);
  const [validationError, setValidationError] = useState("");
  const [insuranceLink, setInsuranceLink] = useState("");

  

  useEffect(() => {
    //console.log(userData)
    if (data.key === "current_health_insurance_info") {
      setTmpData({
        current_health_insurance:
          extraData?.currentInsuranceName || "Nicht ausgewählt",
      });
    } else {
      //add more
      //const formattedSalary = Number(extraData?.salary).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      setTmpData({ income: extraData?.salary, ...tmpData });
    }
    //append links
    if (data.key === "extra") {
      const link = userData.find(
        (k) => k._id === extraData.selectedInsurance._id
      );
      setInsuranceLink(link?.dataProtectionLink);
    }
  }, [extraData, data.key]);

  useEffect(() => {
    //console.log(isVisible)
    if (isOpen === itemPos) {
      setIsExpanded(true);
      setIsEditable(true);
    }
    if (data.key === "additional_info" || data.key === "employer_info") {
      if (isVisible === true) {
        setEmploymentVisibility(1);
      } else {
        setSelfEmploymentVisibility(1);
      }
    }
    if (data.key === "sign_off_info") {
      tmpData["birth_name"] = localStorage.getItem("lastname") || "";
    }
  }, [isOpen, itemPos, data.key, extraData]);

  //HARD EFFECT
  // useEffect(() => {
  //   setTmpData({
  //     ...tmpData,
  //     birth_name: new Date().toLocaleTimeString()
  //   });
  // }, [tmpData?.firstname]);

  const onTypeEffect = (e, fieldKey) => {
    setTmpData((prev) => ({
      ...prev,
      [fieldKey]: e.target.value,
    }));
    //see console
    if (fieldKey === "firstname" || fieldKey === "lastname") {
      localStorage.setItem(fieldKey, e.target.value);
    }
  };

  const btnAddData = () => {
    if (validateSection()) {
      onSave({ data: { [data.key]: tmpData }, currentIndex: isOpen });
      setTmpDataPrev({ ...data });
      setIsEditable(false);
      setValidationError("");
    } else {
      setValidationError("Bitte füllen Sie alle Pflichtfelder aus.");
    }
  };
  const validateSection = () => {
    //this if statement is for checking if the data protection form was answered with yes
    if (
      (data.fields[0].fields.key === "data_protection_indento" ||
        data.fields[1].fields.key === "data_protection_insurance") &&
      (tmpData["data_protection_indento"] === "Nein" ||
        tmpData["data_protection_insurance"] === "Nein")
    ) {
        return false;
    }
    return data.fields.every((field) => {
      if (field.group) {
        return field.fields.every((subField) => tmpData[subField.key]);
      }
      return tmpData[field.fields.key];
    });
  };

  const _getBoolean = (str) => {
    if (str?.toLowerCase() === "yes") return true;
    if (str?.toLowerCase() === "no") return false;
    return str;
  };

  const RadioButton = ({ label, value, checked, onChange }) => (
    <label className="flex items-center cursor-pointer p-2 rounded-lg border border-gray-300 w-full">
      <input
        type="radio"
        className="hidden"
        value={value}
        checked={checked}
        onChange={onChange}
        style={{ fontSize: 16 }}
      />
      <div
        className={`w-6 h-6 rounded-full border-2 mr-2 flex items-center justify-center ${
          checked ? "border-blue-500" : "border-gray-400"
        }`}
      >
        {checked && <div className="w-4 h-4 rounded-full bg-[#0098D6]"></div>}
      </div>
      <span className="text-gray-700" style={{ fontSize: 16 }}>
        {label}
      </span>
    </label>
  );

  const CustomerBonus = () => {
    return (
      <>
        <div className="flex space-x-4 w-full mt-4">
          <div
            style={{
              width: "100%",
              borderColor: "#6cd25c",
              borderWidth: 1,
              padding: 10,
              borderRadius: 10,
            }}
          >
            <p style={{ fontSize: 16, color: "#44ac34" }}>
              Unser Dankeschön an Sie
            </p>
            <div style={{ display: "inline-flex" }}>
              <label className="container2" style={{ marginRight: 5 }}>
                <input
                  checked={true}
                  type={"checkbox"}
                  style={{ marginRight: 10, borderColor: "#44ac34" }}
                />
                <span className="checkmark"></span>
              </label>
              <div>
                <p style={{ fontSize: 16, color: "#8a8a8a" }}>
                  27 Euro Zahn-Zusatzversicherung
                </p>
                <p style={{ fontSize: 14, color: "#8a8a8a" }}>
                  Ihre gewählte Prämie erhalten Sie per E-Maill
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderField = (field) => {
    if (field.type === "option" && field.listItems.length === 2) {
      const [yesOption, noOption] = field.listItems;
      return (
        <div className="flex space-x-4 w-full">
          <RadioButton
            label={yesOption.title || yesOption}
            value={_getBoolean(yesOption.title || yesOption)}
            checked={
              tmpData[field.key] === _getBoolean(yesOption.title || yesOption)
            }
            onChange={(e) => onTypeEffect(e, field.key)}
            style={{ fontSize: 16 }}
          />
          <RadioButton
            label={noOption.title || noOption}
            value={_getBoolean(noOption.title || noOption)}
            checked={
              tmpData[field.key] === _getBoolean(noOption.title || noOption)
            }
            onChange={(e) => onTypeEffect(e, field.key)}
            style={{ fontSize: 16 }}
          />
        </div>
      );
    } else if (field.type === "option") {
      return (
        <select
          disabled={field.disabled}
          value={tmpData[field.key] || ""}
          className="bg-white border border-gray-300 w-full text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-primary block p-2"
          required
          onChange={(e) => onTypeEffect(e, field.key)}
          style={{ fontSize: 16, borderRadius: 5 }}
        >
          <option value="">--Wählen--</option>
          {field.listItems.map((item, index) => (
            <option
              style={{ fontSize: 16 }}
              key={index}
              value={_getBoolean(item.title || item)}
            >
              {item.title || item}
            </option>
          ))}
        </select>
      );
    } else {
      return (
        <input
          style={{ fontSize: 16, borderRadius: 5 }}
          disabled={field.disabled}
          value={tmpData[field.key] || ""}
          type={field.type}
          placeholder={field.title}
          className="bg-white border border-gray-300 w-full text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-primary block p-2"
          required
          onChange={(e) => onTypeEffect(e, field.key)}
          maxLength={field.type === "text" ? 255 : undefined}
        />
      );
    }
  };

  if (employmentVisibility === 1 && !data.isEmployed) {
    return null;
  }

  if (selfEmploymentVisibility === 1 && data.isEmployed) {
    return null;
  }

  return (
    <>
      <div className="mb-4" key={"samples-keys"}>
        <div
          className={"flex flex-col w-full"}
          style={{ borderColor: "#d0d0d0", borderWidth: 1, padding: 20 }}
        >
          <div className="flex flex-col w-full" style={{ marginBottom: 0 }}>
            <div className="flex flex-row w-full space-x-1">
              <p
                onClick={() => {
                  if (data.key === "sign_off_info") {
                    tmpData["birth_name"] =
                      localStorage.getItem("lastname") || "";
                  }
                  if (isOpen >= itemPos) {
                    setIsExpanded(!isExpanded);
                    setIsExpandedBefore(true);
                  } else if (isExpandedBefore) {
                    setIsExpanded(!isExpanded);
                  } else {
                    toast.warn("Bitte füllen Sie die vorherige Sektion aus...");
                    //setIsExpandedBefore(true)
                  }
                  //expand
                }}
                style={{ color: "#00508b", cursor: "pointer" }}
                className="text-black text-[22px] w-full"
              >
                {title}
              </p>

              <p
                className="pull-right edit-buttonEffect"
                style={{ cursor: "pointer", display: isExpanded ? "" : "none" }}
                onClick={() => {
                  setIsEditable(!isEditable);
                  if (data.key === "sign_off_info") {
                    tmpData["birth_name"] =
                      localStorage.getItem("firstname") || "";
                  }
                }}
              >
                <span style={{ float: "right" }}>
                  <img src={"/pencil.png"} alt={""} />
                </span>
              </p>
            </div>
          </div>

          <div
            style={{ display: isExpanded ? "" : "none" }}
            key={"main-container"}
          >
            <div
              style={{ display: !isEditable ? "" : "none" }}
              key={"main-container-2"}
            >
              <div className="flex flex-col w-full">
                {tmpDataPrev.fields.map((item, index) => (
                  <div
                    className="flex flex-row w-full space-x-1 my-1"
                    key={"j-" + index}
                  >
                    <h3 className="text-black text-[16px] font-bold w-full">
                      {item.title}
                    </h3>
                    {item.group ? (
                      <h3
                        className="text-secondary text-[13px] font-bold w-full"
                        style={{ color: "#34b655", fontSize: 16 }}
                      >
                        {item?.fields?.map(
                          (x, i) => (tmpData[x.key] || "-") + " "
                        )}
                      </h3>
                    ) : (
                      <h3
                        className="text-secondary text-[13px] font-bold w-full"
                        style={{ color: "#34b655", fontSize: 16 }}
                      >
                        {item.fields.type !== "ui"
                          ? tmpData[item.fields.key] || "--"
                          : ""}
                      </h3>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div style={{ display: isEditable ? "" : "none" }}>
              <div className="space-y-5 w-full my-5">
                {data.fields.map((d, i) => (
                  <div
                    className="flex flex-col w-full items-start justify-between"
                    key={i + 1}
                  >
                    <label
                      htmlFor={d.fields.key || d.key}
                      className="block mb-2 text-[16px] font-medium text-black"
                    >
                      {d.title}
                      {d?.links ? (
                        <>
                          <a
                            style={{ color: "rgb(0 152 214)", marginLeft: 10 }}
                            target={"_blank"}
                            href={
                              d.fields.key === "data_protection_insurance"
                                ? insuranceLink
                                : d?.links[0]?.url
                            }
                          >
                            {d?.links[0]?.title}
                          </a>
                        </>
                      ) : null}
                    </label>
                    <div className="w-full">
                      {d.group ? (
                        <>
                          <div className="flex space-x-4 w-full">
                            {d.fields.map((x, ix) => (
                              <div key={ix} className="mb-2 w-full">
                                {renderField(x)}
                              </div>
                            ))}
                          </div>
                        </>
                      ) : d.showPrivacy ? (
                        <>
                          {renderField(d.fields)}
                          <></>
                        </>
                      ) : (
                        renderField(d.fields)
                      )}
                    </div>
                  </div>
                ))}
                {validationError && (
                  <p className="text-red-600 text-sm">{validationError}</p>
                )}
                <button
                  onClick={btnAddData}
                  className="bg-[#0098D6] hover:bg-[#007bb5] text-white font-bold py-2 px-4 rounded w-full"
                >
                  Speichern
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationPageBasicUI;
